<template>
  <section class="advertising-page">
    <div class="advertising-page-banner">
      <!-- 顶部图 只渲染一次即可 一个站点只有一张 -->
      <Banner
        v-once
        :src="bannerImg"
      />
    </div>
    <!-- 大卡片 -->
    <div
      v-if="isShowBigCard"
      class="advertising-page-card"
    >
      <BigCard 
        ref="refBigCard"
      />
    </div>
    <div class="advertising-page-recommend">
      <!-- 推荐流 -->
      <AdvertisingRecommend
        ref="refRecommend"
        :page-type="pageType"
      />
    </div>
    <div
      v-once
      class="advertising-page-suspension"
      :language-home="language.SHEIN_KEY_PWA_25654"
    >
      <HomeIcon />
    </div>
  </section>
</template>

<script>
import storeModule, {
  storeModuleName
} from '../product_app/store/modules/advertising'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import AdvertisingRecommend from './components/AdvertisingRecommend/Index.vue'
import Banner from './components/Banner'
import HomeIcon from './components/HomeIcon'
import BigCard from './components/BigCard'
import hiddenBackTopMixins from 'public/src/pages/advertising/mixins/hiddenBackTopMixins'
import advertisingAnalysis from './analysis'
import { checkGoodsStock } from './common/utils'
import { cloneDeep } from '@shein/common-function'
export default {
  name: 'AdvertisingPage',
  components: {
    Banner,
    AdvertisingRecommend,
    HomeIcon,
    BigCard
  },
  mixins: [hiddenBackTopMixins],
  provide () {
    return {
      advertisingAnalysis,
      getIsSupportCropImage: () => true
    }
  },
  // SSR 调用 将数据注入仓库
  asyncData ({ store, context }) {
    if (!store.state[storeModuleName]) {
      store.registerModule(storeModuleName, storeModule)
      if (context?.cat_info?.pageName === 'page_advertising') {
        // 首屏数据不做变更 但是不能直接冻结context 因为vue在ssr时还会对context对象做一些拓展
        const contextValue = Object.freeze(cloneDeep(context))
        store.state[storeModuleName].contextForSSR = Object.freeze(contextValue)
      }
    }

  },
  data() {
    return {
      isFirst: true // 首次进入
    }
  },
  computed: {
    ...mapState(storeModuleName, ['pageType', 'hooksGoods', 'locals', 'language']),
    ...mapGetters(storeModuleName, ['bannerImg']),
    isShowBigCard() {
      // A分支 有勾子商品 且在售
      return this.pageType === 'A' && this?.hooksGoods?.goods_id && checkGoodsStock(this?.hooksGoods) && this?.hooksGoods?.goods_name
    }
  },
  created() {
    const { contextForSSR } = this.$store.state[storeModuleName] || {}
    if (contextForSSR) {
      this.resetLocals(contextForSSR)
      this.initData(contextForSSR)
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
    }
  },
  activated() {
    if (!this.isFirst) {
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
      this.resetAnalysis()
    }
    this.isFirst = false
  },
  mounted() {
    if (this.isFirst) {
      advertisingAnalysis.init({
        hooksGoods: this?.hooksGoods
      })
      this.resetAnalysis()
    }
  },
  methods: {
    ...mapActions(storeModuleName, [
      'initState'
    ]),
    ...mapMutations(storeModuleName, [
      'setHooksGoods',
      'setAbtInfo',
      'setPageType',
      'initItemConfig',
      'setLanguage',
      'resetLocals'
    ]),
    initData(payload) {
      payload && this.initState(payload)
    },
    resetAnalysis () {
      advertisingAnalysis.sendPV()
      this.$refs.refBigCard?.resetAnalysis?.()
      this.$refs.refRecommend?.resetAnalysis?.()
    }
  }
}
</script>
