import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

const mutations = {
  setSuggestedSalePriceType(state, data) {
    state.suggestedSalePriceType = data
  },
  setHooksGoods(state, data) {
    state.hooksGoods = data
  },
  setAbtInfo(state, data) {
    state.abtInfo = data
  },
  setPageType(state, data) {
    state.pageType = data
  },
  setLanguage (state, data) {
    state.language = data
  },
  resetLocals(state, payload) {
    if (payload?.locals) return state.locals = Object.freeze(payload.locals)
    if (typeof window === 'undefined') return

    const { WEB_CLIENT, IS_RW, SiteUID, host, lang, langPath,  appLanguage, GB_cssRight, is_include_critical_css, LAZY_IMG, LAZY_IMG_PIXEL, IMG_LINK, LAZY_IMG_SOLID_COLOR, dateLangMap, RESOURCE_SDK, PUBLIC_CDN } = gbCommonInfo
    const _currency = UserInfoManager.get({ key: 'currency' })
    state.locals = {
      WEB_CLIENT,
      IS_RW,
      SiteUID,
      host,
      lang,
      langPath,
      appLanguage,
      GB_cssRight,
      is_include_critical_css,
      LAZY_IMG,
      LAZY_IMG_PIXEL,
      IMG_LINK,
      LAZY_IMG_SOLID_COLOR,
      dateLangMap,
      currency: _currency,
      RESOURCE_SDK,
      PUBLIC_CDN
    }
  },
  initItemConfig (state) {
    const { imageLabel, listKeyAttribute, SellingPoint, AddShowGroup, HideGoodsNum, AllListQuickShip, AllListStarReview, SameLabel, ListProColor, NewStarRating, ListAddToBag, ListLoadmore,  discountLabel, greysellingPoint, LocFilterUpdate, AdultProductAge, listSpuPic, EstimatedPrice } = state.abtInfo
    // const { goodsNameLine } = state.cccConfig?.mobileVerticalView || {}
    const { sheinClubType, isPaid } = state.sheinClubInfo || {}
    const showStarRating = NewStarRating?.p?.NewStarRating == 'StarRating'
    const estimatedPrice = EstimatedPrice?.p?.E_Price_Cal
    state.itemConfig = {
      ...state.itemConfig,
      itemDAEventClickId: '2-3-1',
      itemDAEventExposeId: '2-3-2',
      // 埋点
      useOwnClickExposeAnalysis: false,
      sa: {
        activity_from: 'goods_list'
      },
      // 【三种卡片】都能展示的样式
      showSeriesBrand: true, // 品牌系列
      showAddBagBtn: true, // 加车按钮
      showPromoLabel: true,
      showTitle: true,
      // showTitle: !!Number(goodsNameLine),
      // showTitleTwoLine: Number(goodsNameLine) === 2,
      configReady: true,
      showMultiColor: true,
      hideGoodsNum: `${HideGoodsNum?.p}` !== 'false',  // 关闭商品数量
      // 是否显示泛列表新增购物车浮窗icon
      showCartBag: ListAddToBag?.p?.ListAddToBag === 'ListBag',

      // ------ 下面是商品项的配置
      hideRetailPrice: true,
      hideDiscountBadge: true,
      showSelectOnLongClick: true,  // 长按反馈flag
      showSellingPoint: SellingPoint?.p === 'type=sellingpoint',
      showBadge: true,
      // 是否将快速加车按钮移动到主图下面
      showAddBagAfterGoodsPrice: true,
      // 色块的展示方式: 默认/横排/竖排: 空值, ProColorA, ProColorB; 注意: 值为 NONE 也属于是空值
      colorSetPresentedType: ['ProColorA', 'ProColorB'].includes(ListProColor?.p?.ListProColor) ? ListProColor?.p?.ListProColor : '',
      // colorSetPresentedType: false,
      showColorSellOut: false,
      showFeedback: true,
      showSpuImg: listSpuPic?.p?.ListSpuPic === 'show',
      showAddToBoard: AddShowGroup?.p === 'ShowGroup',
      showSheinClubDiscountValue: sheinClubType == 1,
      // showSheinClubDiscountValuePriceRight: true,
      showSheinClubDiscountValueAfterPrice: sheinClubType == 2,
      showSheinClubPriceOnSale: sheinClubType == 2 && isPaid,
      showQuickShip: AllListQuickShip?.p === 'type=B', // 快速发货标签
      isListstarB: AllListStarReview?.p?.liststar === 'B',
      showStarComment: !showStarRating && ['star', 'B'].includes(AllListStarReview?.p?.liststar), // 星级评论
      showStarRating: showStarRating, // 星级评分
      showDiscountLabelAfterPrice: true,
      showBestDealLabel: ['banner', 'bottom', 'button'].includes(SameLabel?.p?.SameLabel), // 保留款
      showCropImg: ListLoadmore?.p === 'type=waterfall', // 图片剪裁
      // showCateName: cateName?.p?.cateName == 'show' && !!filterCates?.[0]?.children?.length, // 分类名称
      showLocateLabels: imageLabel?.p?.imageLabel, // 主图角标
      showAdultProductDialog: AdultProductAge?.p?.AdultProductAge === 'A',
      // showPictureThirdPartLabel: picture3plabel?.p?.picture3plabel === 'show',
      showEstimatedPriceReachThreshold: estimatedPrice === 'Have_Threshold' || estimatedPrice === 'Non_Threshold', // 到手价（满足门槛）
      // showEstimatedPrice: estimatedPrice === 'Non_Threshold', // 到手价（满足门槛和未满足门槛）
      showDecisionAttr: listKeyAttribute?.p?.keyAttribute === 'show',
      showNewDiscountLabel: discountLabel?.p?.discountLabel === 'Label', // 折扣标签ABT
      showGreySellingPoint: greysellingPoint?.p?.greysellingPoint === 'grey', // 卖点标签ABT
      searchFiltersWithoutSellingPoint: LocFilterUpdate?.param?.NoSellingPoint === 'new', // 隐藏分布式筛选中的卖点
      // TODO：待确认是否加abt
      showRankingList: true
    }
  },
  initCccConfig (state, data) {
    state.cccConfig = data
  },
  initSheinClubInfo (state, data) {
    state.sheinClubInfo = data
  },
  initCatInfo (state, data) {
    state.catInfo = data
  },
  initAtomicFields (state, data) {
    state.atomicFields = data
  },
  initGoodsItemInfo (state) {
    state.goodsItemInfo = new GoodsItemInfo(({
      locals: state.locals,
      itemLanguage: state.language
    }))
  }
}

export default mutations
