
const getters = {
  recSwitchCate (state) {
    return state.abtInfo?.recSwitch?.p?.recSwitch?.split('|')?.includes('146')
  },
  recSwitchOther (state) {
    return state.abtInfo?.recSwitch?.p?.recSwitch?.split('|')?.includes('167')
  },
  bannerImg (state) {
    return state.contextForSSR?.bannerImg ?? ''
  }
}

export default getters
